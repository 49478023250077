<template>
    <b-container class="mt-4">
        <b-alert v-model="showSavedAlert" variant="success" dismissible>
            The Settings have been saved!
        </b-alert>
        <b-form @submit.prevent="submit">
            <div class="section">
                <h4 class="mb-2">Date Format</h4>
                <b-row>
                    <b-col cols="4">
                        <b-form-select
                            v-model="settings.genericSettings.dateFormat"
                        >
                            <b-form-select-option
                                v-for="format in dateFormatOptions"
                                :key="format.value"
                                :value="format.value"
                                >{{
                                    format.value +
                                    " (" +
                                    getFormatedDateString(format.value) +
                                    ")"
                                }}</b-form-select-option
                            >
                        </b-form-select>
                    </b-col>
                </b-row>
                <p class="field-info-message mt-2 mb-0">
                    Please make sure that the date format is same as the CRM
                    date format.
                </p>
            </div>
            <div class="section account">
                <h4 class="mb-2">
                    Select Account Fields
                    <svg
                        v-b-tooltip.hover
                        title="Tick the following boxes to sync them into your CRM. Only the ticked boxes will sync with your Account details. Unticked boxes will no longer sync."
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-info-circle"
                        viewBox="0 0 16 16"
                    >
                        <path
                            d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                        />
                        <path
                            d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"
                        />
                    </svg>
                </h4>
                <b-row>
                    <b-col
                        cols="4"
                        v-for="setting in settings.Accounts"
                        :key="setting.api_name"
                    >
                        <b-form-checkbox
                            @change="settingsChanged"
                            v-model="setting.value"
                            :value="true"
                            :unchecked-value="false"
                        >
                            {{ setting.text }}
                        </b-form-checkbox>
                    </b-col>
                </b-row>
            </div>
            <div class="section vendor">
                <h4 class="mb-2">
                    Select Vendor Fields
                    <svg
                        v-b-tooltip.hover
                        title="Tick the following boxes to sync them into your CRM. Only the ticked boxes will sync with your Vendor details. Unticked boxes will no longer sync."
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-info-circle"
                        viewBox="0 0 16 16"
                    >
                        <path
                            d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                        />
                        <path
                            d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"
                        />
                    </svg>
                </h4>
                <b-row>
                    <b-col
                        cols="4"
                        v-for="setting in settings.Vendors"
                        :key="setting.api_name"
                    >
                        <b-form-checkbox
                            @change="settingsChanged"
                            v-model="setting.value"
                            :value="true"
                            :unchecked-value="false"
                        >
                            {{ setting.text }}
                        </b-form-checkbox>
                    </b-col>
                </b-row>
            </div>
            <b-button
                class="save-button btn-right-aligned"
                type="submit"
                variant="primary"
                >Save</b-button
            >
        </b-form>
    </b-container>
</template>

<style scoped>
.section {
    margin: 2rem 0;
}
.save-button {
    padding: 0.375rem 2rem;
}
.btn-right-aligned {
    display: block;
    margin-left: auto;
    margin-right: 18%;
}
.field-info-message {
    font-size: 12px;
}
</style>

<script>
import moment from "moment";
export default {
    name: "settings",
    data: () => ({
        showSavedAlert: false,
        dateFormatOptions: [
            { value: "DD-MM-YYYY" },
            { value: "DD/MM/YYYY" },
            { value: "DD.MM.YYYY" },
            { value: "DD MM YYYY" },
            { value: "DD-MM-YY" },
            { value: "DD/MM/YY" },
            { value: "DD.MM.YY" },
            { value: "D.MM.YY" },
            { value: "D-M-YY" },
            { value: "D/M/YY" },
            { value: "D.M.YY" },
            { value: "D.M.YY." },
            { value: "D-M-YYYY" },
            { value: "D/M/YYYY" },
            { value: "D.M.YYYY" },
            { value: "D. M. YYYY." },
            { value: "D. M. YYYY" },
            { value: "D MMM, YYYY" },
            { value: "DD MMM, YYYY" },
            { value: "D. MMMM YYYY" },
            { value: "DD.MM.YYYY." },
            { value: "DD.MM.YY." },
            { value: "YYYY-MM-DD" },
            { value: "YYYY/MM/DD" },
            { value: "YYYY.MM.DD" },
            { value: "YYYY MM DD" },
            { value: "YY/MM/DD" },
            { value: "YY-MM-DD" },
            { value: "YY.M.D" },
            { value: "YY-M-D" },
            { value: "YY. M. D" },
            { value: "YYYY/M/D" },
            { value: "YYYY年MM月DD日" },
            { value: "YY年M月D日" },
            { value: "YYYY.MM.DD." },
            { value: "YYYY. MM. DD" },
            { value: "MM-DD-YYYY" },
            { value: "MM/DD/YYYY" },
            { value: "MM.DD.YYYY" },
            { value: "MM DD YYYY" },
            { value: "MM-DD-YY" },
            { value: "MM/DD/YY" },
            { value: "M/DD/YY" },
            { value: "MMM-DD-YYYY" },
            { value: "MMM DD, YYYY" },
            { value: "MMM D, YYYY" },
            { value: "MMMM D, YYYY" },
            { value: "YYYY.DD.MM" },
            { value: "YY.D.M" },
        ],
    }),
    computed: {
        settings() {
            return this.$store.getters.fillableSettings;
        },
    },
    async mounted() {
        // console.log("settings: ", this.settings);
    },
    methods: {
        settingsChanged() {},
        submit() {
            this.$store.dispatch("setFillableSettings", this.settings);

            ZOHO.CRM.CONNECTOR.invokeAPI("crm.set", {
                apiname: "zohocrm2abn__Fillable_Properties",
                value: this.settings,
            }).then((e) => {
                this.showSavedAlert = true;
                // window.location.reload();
            });
            // console.log(this.settings);
        },
        getFormatedDateString(formatString) {
            return moment().format(formatString);
        },
    },
};
</script>
